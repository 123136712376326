/* Autogenerated file. Do not modify */
'use strict'
const skins = {}
 skins['wysiwyg.viewer.skins.button.AddProductButton'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "trans1": "TRANSITION",
    "brd": "BORDER_COLOR",
    "brw": "BORDER_SIZE",
    "fnt": "FONT",
    "trans2": "TRANSITION",
    "txt": "TEXT_COLOR",
    "bgh": "BG_COLOR_ALPHA",
    "brdh": "BORDER_COLOR",
    "txth": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "rd": "0",
    "bg": "color_17",
    "trans1": "border-color 0.4s ease 0s, background-color 0.4s ease 0s",
    "brd": "color_15",
    "brw": "0",
    "fnt": "font_7",
    "trans2": "color 0.4s ease 0s",
    "txt": "color_15",
    "bgh": "color_18",
    "brdh": "color_16",
    "txth": "color_15"
  },
  "css": {
    "%link": "[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  border:solid [brd] [brw];cursor:pointer !important;",
    "%label": "[fnt]  [trans2]  color:[txt];white-space:nowrap;margin:calc(-1 * [brw]) [brw] 0 [brw];display:inline-block;position:relative;",
    "%:hover %link": "background-color:[bgh];border-color:[brdh];[trans1]",
    "%:hover %label": "color:[txth];[trans2]"
  }
}
 skins['wysiwyg.viewer.skins.button.ApplyButtonEcom'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "bgh": "BG_COLOR_ALPHA",
    "brdh": "BORDER_COLOR_ALPHA",
    "txth": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "rd": "0",
    "bg": "color_17",
    "brw": "0",
    "brd": "color_15",
    "fnt": "font_8",
    "txt": "color_15",
    "bgh": "color_18",
    "brdh": "color_15",
    "txth": "color_15"
  },
  "css": {
    "%link": "[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];border:[brw] [brd] solid;cursor:pointer !important;",
    "%label": "[fnt]  color:[txt];white-space:nowrap;margin:1px [brw] 0 [brw];display:inline-block;position:relative;",
    "%:hover %link": "background-color:[bgh];border-color:[brdh];",
    "%:hover %label": "color:[txth];"
  }
}
 skins['wysiwyg.viewer.skins.button.BasicButton'] = {
  "react": [
    [
      "a",
      "link",
      [
        "_g!g-transparent-a"
      ],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "trans1": "TRANSITION",
    "shd": "BOX_SHADOW",
    "fnt": "FONT",
    "trans2": "TRANSITION",
    "txt": "TEXT_COLOR",
    "brw": "BORDER_SIZE",
    "bg": "BG_COLOR_ALPHA",
    "brd": "BORDER_COLOR_ALPHA",
    "bgh": "BG_COLOR_ALPHA",
    "brdh": "BORDER_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "bgd": "BG_COLOR_ALPHA",
    "brdd": "BORDER_COLOR_ALPHA",
    "txtd": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "rd": "0",
    "trans1": "border-color 0.4s ease 0s, background-color 0.4s ease 0s",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "fnt": "font_5",
    "trans2": "color 0.4s ease 0s",
    "txt": "color_15",
    "brw": "0",
    "bg": "color_17",
    "brd": "color_15",
    "bgh": "color_18",
    "brdh": "color_15",
    "txth": "color_15",
    "bgd": "#CCCCCC",
    "brdd": "color_15",
    "txtd": "#FFFFFF"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%link": "[rd]  position:absolute;top:0;right:0;bottom:0;left:0;[trans1]  [shd]",
    "%label": "[fnt]  [trans2]  color:[txt];display:inline-block;margin:calc(-1 * [brw]) [brw] 0;position:relative;white-space:nowrap;",
    "%[data-state~=\"shouldUseFlex\"] %label": "margin:0;",
    "%[data-disabled=\"false\"] %link": "background-color:[bg];border:solid [brd] [brw];cursor:pointer !important;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %link,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %link,%[data-disabled=\"false\"][data-preview~=\"hover\"] %link": "background-color:[bgh];border-color:[brdh];",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %label,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %label,%[data-disabled=\"false\"][data-preview~=\"hover\"] %label": "color:[txth];",
    "%[data-disabled=\"true\"] %link,%[data-preview~=\"disabled\"] %link": "background-color:[bgd];border:solid [brdd] [brw];",
    "%[data-disabled=\"true\"] %label,%[data-preview~=\"disabled\"] %label": "color:[txtd];"
  }
}
 skins['wysiwyg.viewer.skins.button.BorderButton'] = {
  "react": [
    [
      "div",
      null,
      [
        "_bg"
      ],
      {}
    ],
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "rd2": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "xxx": "BG_COLOR_ALPHA",
    "tdr": "URL",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "brdh": "BG_COLOR_ALPHA",
    "bgh": "BG_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "brdd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "bgd": "BG_COLOR_ALPHA",
    "txtd": "TEXT_COLOR",
    "brd": "BG_COLOR"
  },
  "paramsDefaults": {
    "rd": "10px",
    "rd2": "5px",
    "bg": "color_1",
    "xxx": "color_1",
    "tdr": "BASE_THEME_DIRECTORY",
    "fnt": "font_5",
    "txt": "color_5",
    "brdh": "color_6",
    "bgh": "color_2",
    "txth": "color_6",
    "brdd": "color_6",
    "brw": "0",
    "bgd": "#CCCCCC",
    "txtd": "#FFFFFF",
    "brd": "color_5"
  },
  "paramsMutators": {
    "brd": {
      "type": "alpha",
      "value": 0.6
    }
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%": "[rd]  background-color:[brd];",
    "%_bg": "[rd2]  background-color:[bg];position:absolute;top:5px;bottom:5px;left:5px;right:5px;background:[xxx] url([tdr]net.png) center center repeat;",
    "%link": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%label": "position:relative;[fnt]  color:[txt];white-space:nowrap;padding:15px;",
    "%[data-disabled=\"false\"]": "cursor:pointer;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"],%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"],%[data-disabled=\"false\"][data-preview~=\"hover\"]": "background-color:[brdh];",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %_bg,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %_bg,%[data-disabled=\"false\"][data-preview~=\"hover\"] %_bg": "background-color:[bgh];",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %label,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %label,%[data-disabled=\"false\"][data-preview~=\"hover\"] %label": "color:[txth];",
    "%[data-disabled=\"true\"],%[data-preview~=\"disabled\"]": "border:solid [brdd] [brw];",
    "%[data-disabled=\"true\"] %_bg,%[data-preview~=\"disabled\"] %_bg": "background-color:[bgd];",
    "%[data-disabled=\"true\"] %label,%[data-preview~=\"disabled\"] %label": "color:[txtd];"
  }
}
 skins['wysiwyg.viewer.skins.button.BrandButton'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "shadowColor": "BOX_SHADOW_COLOR_ALPHA",
    "shadowColor2": "BOX_SHADOW_COLOR_ALPHA",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "trans1": "TRANSITION",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "trans2": "TRANSITION",
    "bgh": "BG_COLOR_ALPHA",
    "brdh": "BORDER_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "bga": "BG_COLOR_ALPHA",
    "brda": "BORDER_COLOR_ALPHA",
    "txta": "TEXT_COLOR",
    "bgd": "BG_COLOR_ALPHA",
    "brdd": "BORDER_COLOR_ALPHA",
    "txtd": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "rd": "0",
    "bg": "color_17",
    "shadowColor": "#000102",
    "shadowColor2": "#000102",
    "brd": "color_15",
    "brw": "0",
    "trans1": "border-color 0.2s ease 0s, background-color 0.2s ease 0s",
    "fnt": "font_5",
    "txt": "color_15",
    "trans2": "color 0.2s ease 0s",
    "bgh": "color_18",
    "brdh": "color_15",
    "txth": "color_15",
    "bga": "color_17",
    "brda": "color_15",
    "txta": "color_15",
    "bgd": "#CCCCCC",
    "brdd": "color_15",
    "txtd": "#FFFFFF"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%link": "[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];box-shadow:0px 1px 1px 0px [shadowColor], 0px 4px 6px 0px [shadowColor2];border:solid [brd] [brw];[trans1]",
    "%label": "[fnt]  color:[txt];display:inline-block;margin:calc(-1 * [brw]) [brw] 0;position:relative;white-space:nowrap;[trans2]",
    "%[data-disabled=\"false\"]": "cursor:pointer !important;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %link,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %link,%[data-disabled=\"false\"][data-preview~=\"hover\"] %link": "background-color:[bgh];border:solid [brdh] [brw];",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %label,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %label,%[data-disabled=\"false\"][data-preview~=\"hover\"] %label": "color:[txth];",
    "%[data-disabled=\"false\"]:active[data-state~=\"desktop\"] %link": "background-color:[bga];border:solid [brda] [brw];transition:none;",
    "%[data-disabled=\"false\"]:active[data-state~=\"desktop\"] %label": "color:[txta];transition:none;",
    "%[data-disabled=\"true\"] %link,%[data-preview~=\"disabled\"] %link": "background-color:[bgd];border:solid [brdd] [brw];",
    "%[data-disabled=\"true\"] %label,%[data-preview~=\"disabled\"] %label": "color:[txtd];"
  }
}
 skins['wysiwyg.viewer.skins.button.ButtonArrow'] = {
  "react": [
    [
      "div",
      null,
      [
        "_arr",
        "_top"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_arr",
        "_bottom"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_bg"
      ],
      {}
    ],
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "maxH": 100
  },
  "params": {
    "bgh": "BORDER_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "bg": "BORDER_COLOR_ALPHA",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "bgd": "BORDER_COLOR_ALPHA",
    "txtd": "TEXT_COLOR",
    "brdd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "bgh": "color_18",
    "txth": "color_15",
    "bg": "color_17",
    "fnt": "font_5",
    "txt": "color_15",
    "bgd": "#CCCCCC",
    "txtd": "#FFFFFF",
    "brdd": "color_18",
    "brw": "0"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%": "overflow:hidden;",
    "%[data-disabled=\"false\"]": "cursor:pointer !important;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %_bg,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %_bg,%[data-disabled=\"false\"][data-preview~=\"hover\"] %_bg": "background:[bgh];",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %label,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %label,%[data-disabled=\"false\"][data-preview~=\"hover\"] %label": "color:[txth];",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %_top,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %_top,%[data-disabled=\"false\"][data-preview~=\"hover\"] %_top": "border-bottom-color:[bgh] !important;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %_bottom,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %_bottom,%[data-disabled=\"false\"][data-preview~=\"hover\"] %_bottom": "border-top-color:[bgh] !important;",
    "%_bg": "background:[bg];position:absolute;top:0;right:50px;bottom:0;left:0;",
    "%link": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%label": "[fnt]  color:[txt];display:inline-block;position:relative;white-space:nowrap;",
    "%_top,%_bottom": "border-left:0;border-right:50px solid transparent;position:absolute;right:0;",
    "%[data-state~=\"mobile\"] %_top,%[data-state~=\"mobile\"] %_bottom": "right:1px;",
    "%_top": "border-bottom:50px solid [bg];bottom:50% !important;",
    "%[data-state~=\"mobile\"] %_top": "transform:scale(1.01);",
    "%_bottom": "border-top:50px solid [bg];top:50% !important;",
    "%[data-disabled=\"true\"] %_bg,%[data-preview~=\"disabled\"] %_bg": "background:[bgd];",
    "%[data-disabled=\"true\"] %label,%[data-preview~=\"disabled\"] %label": "color:[txtd];",
    "%[data-disabled=\"true\"] %_top,%[data-preview~=\"disabled\"] %_top": "border-bottom-color:[brdd];border:[brw] solid;",
    "%[data-disabled=\"true\"] %_bottom,%[data-preview~=\"disabled\"] %_bottom": "border-top-color:[brdd];border:[brw] solid;"
  }
}
 skins['wysiwyg.viewer.skins.button.ButtonArrowLeft'] = {
  "react": [
    [
      "div",
      null,
      [
        "_arr",
        "_top"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_arr",
        "_bottom"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_bg"
      ],
      {}
    ],
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "bgh": "BORDER_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "bg": "BORDER_COLOR_ALPHA",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "bgd": "BORDER_COLOR_ALPHA",
    "txtd": "TEXT_COLOR",
    "brdd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "bgh": "color_18",
    "txth": "color_15",
    "bg": "color_17",
    "fnt": "font_5",
    "txt": "color_15",
    "bgd": "#CCCCCC",
    "txtd": "#FFFFFF",
    "brdd": "color_17",
    "brw": "0"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%": "overflow:hidden;transform:rotate(360deg);",
    "%[data-disabled=\"false\"]": "cursor:pointer !important;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %_bg,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %_bg,%[data-disabled=\"false\"][data-preview~=\"hover\"] %_bg": "background:[bgh];",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %label,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %label,%[data-disabled=\"false\"][data-preview~=\"hover\"] %label": "color:[txth];",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %_top,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %_top,%[data-disabled=\"false\"][data-preview~=\"hover\"] %_top": "border-bottom-color:[bgh] !important;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %_bottom,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %_bottom,%[data-disabled=\"false\"][data-preview~=\"hover\"] %_bottom": "border-top-color:[bgh] !important;",
    "%_bg": "background:[bg];position:absolute;top:0;right:0;bottom:0;left:50px;",
    "%link": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%label": "[fnt]  color:[txt];display:inline-block;position:relative;white-space:nowrap;",
    "%_top,%_bottom": "border-left:50px solid transparent;border-right:0;left:0;position:absolute;",
    "%[data-state~=\"mobile\"] %_top,%[data-state~=\"mobile\"] %_bottom": "left:1px;",
    "%_top": "border-bottom:50px solid [bg];bottom:50%;",
    "%[data-state~=\"mobile\"] %_top": "transform:scale(1.01);",
    "%_bottom": "border-top:50px solid [bg];top:50%;",
    "%[data-disabled=\"true\"] %_bg,%[data-preview~=\"disabled\"] %_bg": "background:[bgd];",
    "%[data-disabled=\"true\"] %label,%[data-preview~=\"disabled\"] %label": "color:[txtd];",
    "%[data-disabled=\"true\"] %_top,%[data-preview~=\"disabled\"] %_top": "border-bottom-color:[brdd];border:[brw] solid;",
    "%[data-disabled=\"true\"] %_bottom,%[data-preview~=\"disabled\"] %_bottom": "border-top-color:[brdd];border:[brw] solid;"
  }
}
 skins['wysiwyg.viewer.skins.button.ButtonDoubleArrowLeft'] = {
  "react": [
    [
      "div",
      null,
      [
        "_wrapper"
      ],
      {},
      [
        "div",
        null,
        [
          "_container",
          "_left"
        ],
        {},
        [
          "div",
          null,
          [
            "_ribbon"
          ],
          {}
        ]
      ],
      [
        "div",
        null,
        [
          "_container",
          "_top",
          "_right"
        ],
        {},
        [
          "div",
          null,
          [
            "_ribbon"
          ],
          {}
        ]
      ],
      [
        "div",
        null,
        [
          "_container",
          "_bottom",
          "_right"
        ],
        {},
        [
          "div",
          null,
          [
            "_ribbon"
          ],
          {}
        ]
      ],
      [
        "div",
        null,
        [
          "_filler"
        ],
        {}
      ]
    ],
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "bg": "BORDER_COLOR_ALPHA",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "txth": "TEXT_COLOR",
    "bgh": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "bg": "color_17",
    "fnt": "font_5",
    "txt": "color_15",
    "txth": "color_15",
    "bgh": "color_18"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%_wrapper": "position:absolute;top:0;right:0;bottom:0;left:0;width:50%;",
    "%_container": "position:absolute;height:50%;overflow:hidden;width:80%;",
    "%_left": "height:100%;",
    "%_left %_ribbon": "height:100%;border:0;",
    "%_right": "left:120%;",
    "%_bottom": "bottom:0;",
    "%_ribbon": "position:absolute;width:100%;height:0;border-style:solid;",
    "%_right %_ribbon": "right:0;",
    "%_top %_ribbon": "top:0;",
    "%_bottom %_ribbon": "bottom:0;",
    "%_top%_right %_ribbon": "border-color:[bg] transparent transparent transparent;border-width:500px 500px 0 0;",
    "%_bottom%_right %_ribbon": "border-color:transparent transparent [bg] transparent;border-width:0 500px 500px 0;",
    "%_left %_ribbon:before": "border-width:500px;position:absolute;content:\"\";overflow:hidden;left:0;width:100%;border-style:solid;border-color:[bg] transparent;top:50%;",
    "%_left %_ribbon:after": "border-width:500px;position:absolute;content:\"\";overflow:hidden;left:0;width:100%;border-style:solid;border-color:[bg] transparent;bottom:50%;",
    "%_filler": "position:absolute;background-color:[bg];width:40%;height:100%;left:80%;",
    "%link": "position:absolute;top:0;right:0;bottom:0;left:0;overflow:hidden;height:100%;cursor:pointer !important;",
    "%label": "[fnt]  color:[txt];white-space:nowrap;display:inline-block;position:relative;",
    "%:active[data-state~=\"mobile\"] %label,%:hover[data-state~=\"desktop\"] %label,%[data-preview~=\"hover\"] %label": "color:[txth];",
    "%:active[data-state~=\"mobile\"] %_top%_right %_ribbon,%:hover[data-state~=\"desktop\"] %_top%_right %_ribbon,%[data-preview~=\"hover\"] %_top%_right %_ribbon": "border-top-color:[bgh];",
    "%:active[data-state~=\"mobile\"] %_bottom%_right %_ribbon,%:hover[data-state~=\"desktop\"] %_bottom%_right %_ribbon,%[data-preview~=\"hover\"] %_bottom%_right %_ribbon": "border-bottom-color:[bgh];",
    "%:active[data-state~=\"mobile\"] %_left %_ribbon:before,%:hover[data-state~=\"desktop\"] %_left %_ribbon:before,%[data-preview~=\"hover\"] %_left %_ribbon:before": "border-color:[bgh] transparent;",
    "%:active[data-state~=\"mobile\"] %_left %_ribbon:after,%:hover[data-state~=\"desktop\"] %_left %_ribbon:after,%[data-preview~=\"hover\"] %_left %_ribbon:after": "border-color:[bgh] transparent;",
    "%:active[data-state~=\"mobile\"] %_filler,%:hover[data-state~=\"desktop\"] %_filler,%[data-preview~=\"hover\"] %_filler": "background-color:[bgh];"
  }
}
 skins['wysiwyg.viewer.skins.button.ButtonDoubleArrowRight'] = {
  "react": [
    [
      "div",
      null,
      [
        "_wrapper"
      ],
      {},
      [
        "div",
        null,
        [
          "_container",
          "_top",
          "_left"
        ],
        {},
        [
          "div",
          null,
          [
            "_ribbon"
          ],
          {}
        ]
      ],
      [
        "div",
        null,
        [
          "_container",
          "_bottom",
          "_left"
        ],
        {},
        [
          "div",
          null,
          [
            "_ribbon"
          ],
          {}
        ]
      ],
      [
        "div",
        null,
        [
          "_container",
          "_right"
        ],
        {},
        [
          "div",
          null,
          [
            "_ribbon"
          ],
          {}
        ]
      ],
      [
        "div",
        null,
        [
          "_filler"
        ],
        {}
      ]
    ],
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "bg": "BORDER_COLOR_ALPHA",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "txth": "TEXT_COLOR",
    "bgh": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "bg": "color_17",
    "fnt": "font_5",
    "txt": "color_15",
    "txth": "color_15",
    "bgh": "color_18"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%_wrapper": "position:absolute;top:0;right:0;bottom:0;left:0;width:50%;",
    "%_container": "position:absolute;height:50%;overflow:hidden;width:80%;",
    "%_right": "left:120%;height:100%;",
    "%_bottom": "bottom:0;",
    "%_ribbon": "position:absolute;width:0;height:0;border-style:solid;",
    "%_right %_ribbon": "right:0;height:100%;",
    "%_top %_ribbon": "top:0;",
    "%_bottom %_ribbon": "bottom:0;",
    "%_top%_left %_ribbon": "border-color:transparent [bg] transparent transparent;border-width:0 500px 500px 0;",
    "%_bottom%_left %_ribbon": "border-color:transparent transparent [bg] transparent;border-width:0 0 500px 500px;",
    "%_right %_ribbon:before": "position:absolute;content:\"\";overflow:hidden;right:0;width:100%;border-style:solid;border-color:[bg] transparent;border-width:500px 500px 0 0;top:50%;",
    "%_right %_ribbon:after": "position:absolute;content:\"\";overflow:hidden;right:0;width:100%;border-style:solid;border-color:[bg] transparent;border-width:0 500px 500px 0;bottom:50%;",
    "%_filler": "position:absolute;width:50%;background-color:[bg];width:40%;height:100%;left:80%;",
    "%link": "position:absolute;top:0;right:0;bottom:0;left:0;overflow:hidden;height:100%;cursor:pointer !important;",
    "%label": "[fnt]  color:[txt];white-space:nowrap;display:inline-block;position:relative;",
    "%:active[data-state~=\"mobile\"] %label,%:hover[data-state~=\"desktop\"] %label,%[data-preview~=\"hover\"] %label": "color:[txth];",
    "%:active[data-state~=\"mobile\"] %_top%_left %_ribbon,%:hover[data-state~=\"desktop\"] %_top%_left %_ribbon,%[data-preview~=\"hover\"] %_top%_left %_ribbon": "border-right-color:[bgh];",
    "%:active[data-state~=\"mobile\"] %_bottom%_left %_ribbon,%:hover[data-state~=\"desktop\"] %_bottom%_left %_ribbon,%[data-preview~=\"hover\"] %_bottom%_left %_ribbon": "border-bottom-color:[bgh];",
    "%:active[data-state~=\"mobile\"] %_right %_ribbon:before,%:hover[data-state~=\"desktop\"] %_right %_ribbon:before,%[data-preview~=\"hover\"] %_right %_ribbon:before": "border-color:[bgh] transparent;",
    "%:active[data-state~=\"mobile\"] %_right %_ribbon:after,%:hover[data-state~=\"desktop\"] %_right %_ribbon:after,%[data-preview~=\"hover\"] %_right %_ribbon:after": "border-color:[bgh] transparent;",
    "%:active[data-state~=\"mobile\"] %_filler,%:hover[data-state~=\"desktop\"] %_filler,%[data-preview~=\"hover\"] %_filler": "background-color:[bgh];"
  }
}
 skins['wysiwyg.viewer.skins.button.ButtonForkedLeft'] = {
  "react": [
    [
      "div",
      null,
      [
        "_wrapper"
      ],
      {},
      [
        "div",
        null,
        [
          "_container",
          "_top"
        ],
        {},
        [
          "div",
          null,
          [
            "_ribbon"
          ],
          {}
        ]
      ],
      [
        "div",
        null,
        [
          "_container",
          "_bottom"
        ],
        {},
        [
          "div",
          null,
          [
            "_ribbon"
          ],
          {}
        ]
      ]
    ],
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "bg": "BORDER_COLOR_ALPHA",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "txth": "TEXT_COLOR",
    "bgh": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "bg": "color_17",
    "fnt": "font_5",
    "txt": "color_15",
    "txth": "color_15",
    "bgh": "color_18"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%_wrapper": "position:absolute;top:0;bottom:0;right:0;width:50%;background-color:[bg];",
    "%_container": "position:absolute;height:50%;overflow:hidden;width:100%;right:100%;",
    "%_bottom": "bottom:0;",
    "%_ribbon": "position:absolute;width:0;height:0;left:0;border-style:solid;",
    "%_top %_ribbon": "top:0;border-width:0 500px 500px 0;border-color:transparent [bg] transparent transparent;",
    "%_bottom %_ribbon": "bottom:0;border-color:transparent transparent [bg] transparent;border-width:0 0 500px 500px;",
    "%link": "position:absolute;top:0;right:0;bottom:0;left:0;overflow:hidden;height:100%;cursor:pointer !important;",
    "%label": "[fnt]  color:[txt];white-space:nowrap;display:inline-block;position:relative;",
    "%:active[data-state~=\"mobile\"] %label,%:hover[data-state~=\"desktop\"] %label,%[data-preview~=\"hover\"] %label": "color:[txth];",
    "%:active[data-state~=\"mobile\"] %_top %_ribbon,%:hover[data-state~=\"desktop\"] %_top %_ribbon,%[data-preview~=\"hover\"] %_top %_ribbon": "border-right-color:[bgh];",
    "%:active[data-state~=\"mobile\"] %_bottom %_ribbon,%:hover[data-state~=\"desktop\"] %_bottom %_ribbon,%[data-preview~=\"hover\"] %_bottom %_ribbon": "border-bottom-color:[bgh];",
    "%:active[data-state~=\"mobile\"] %_wrapper,%:hover[data-state~=\"desktop\"] %_wrapper,%[data-preview~=\"hover\"] %_wrapper": "background-color:[bgh];"
  }
}
 skins['wysiwyg.viewer.skins.button.ButtonForkedRight'] = {
  "react": [
    [
      "div",
      null,
      [
        "_wrapper"
      ],
      {},
      [
        "div",
        null,
        [
          "_container",
          "_top"
        ],
        {},
        [
          "div",
          null,
          [
            "_ribbon"
          ],
          {}
        ]
      ],
      [
        "div",
        null,
        [
          "_container",
          "_bottom"
        ],
        {},
        [
          "div",
          null,
          [
            "_ribbon"
          ],
          {}
        ]
      ]
    ],
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "bg": "BORDER_COLOR_ALPHA",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "txth": "TEXT_COLOR",
    "bgh": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "bg": "color_17",
    "fnt": "font_5",
    "txt": "color_15",
    "txth": "color_15",
    "bgh": "color_18"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%_wrapper": "position:absolute;top:0;right:0;bottom:0;left:0;width:50%;background-color:[bg];",
    "%_container": "position:absolute;height:50%;overflow:hidden;width:100%;left:100%;",
    "%_bottom": "bottom:0;",
    "%_ribbon": "position:absolute;width:0;height:0;right:0;border-style:solid;",
    "%_top %_ribbon": "top:0;border-width:500px 500px 0 0;border-color:[bg] transparent transparent transparent;",
    "%_bottom %_ribbon": "bottom:0;border-color:transparent transparent transparent [bg];border-width:500px 0 0 500px;",
    "%link": "position:absolute;top:0;right:0;bottom:0;left:0;overflow:hidden;height:100%;cursor:pointer !important;",
    "%label": "[fnt]  color:[txt];white-space:nowrap;display:inline-block;position:relative;",
    "%:active[data-state~=\"mobile\"] %label,%:hover[data-state~=\"desktop\"] %label,%[data-preview~=\"hover\"] %label": "color:[txth];",
    "%:active[data-state~=\"mobile\"] %_top %_ribbon,%:hover[data-state~=\"desktop\"] %_top %_ribbon,%[data-preview~=\"hover\"] %_top %_ribbon": "border-top-color:[bgh];",
    "%:active[data-state~=\"mobile\"] %_bottom %_ribbon,%:hover[data-state~=\"desktop\"] %_bottom %_ribbon,%[data-preview~=\"hover\"] %_bottom %_ribbon": "border-left-color:[bgh];",
    "%:active[data-state~=\"mobile\"] %_wrapper,%:hover[data-state~=\"desktop\"] %_wrapper,%[data-preview~=\"hover\"] %_wrapper": "background-color:[bgh];"
  }
}
 skins['wysiwyg.viewer.skins.button.ButtonInnerShadow'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "trans1": "TRANSITION",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "fnt": "FONT",
    "trans2": "TRANSITION",
    "txt": "TEXT_COLOR",
    "bgh": "BG_COLOR_ALPHA",
    "brdh": "BORDER_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "bgd": "BG_COLOR_ALPHA",
    "brdd": "BORDER_COLOR_ALPHA",
    "txtd": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "rd": "5px",
    "bg": "color_17",
    "trans1": "border-color 0.4s ease 0s, background-color 0.4s ease 0s",
    "brd": "color_15",
    "brw": "0",
    "fnt": "font_5",
    "trans2": "color 0.4s ease 0s",
    "txt": "color_15",
    "bgh": "color_18",
    "brdh": "color_15",
    "txth": "color_15",
    "bgd": "#CCCCCC",
    "brdd": "color_15",
    "txtd": "#FFFFFF"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%link": "[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  border:solid [brd] [brw];box-shadow:inset 0 1px 2px rgba(0, 0, 0, 0.6), inset 0 -1px 1px rgba(255, 255, 255, 0.75);",
    "%label": "[fnt]  [trans2]  color:[txt];display:inline-block;margin:calc(-1 * [brw]) [brw] 0;position:relative;white-space:nowrap;",
    "%[data-disabled=\"false\"]": "cursor:pointer !important;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %link,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %link,%[data-disabled=\"false\"][data-preview~=\"hover\"] %link": "background-color:[bgh];border:solid [brdh] [brw];[trans1]",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %label,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %label,%[data-disabled=\"false\"][data-preview~=\"hover\"] %label": "color:[txth];[trans2]",
    "%[data-disabled=\"true\"] %link,%[data-preview~=\"disabled\"] %link": "background-color:[bgd];border:solid [brdd] [brw];",
    "%[data-disabled=\"true\"] %label,%[data-preview~=\"disabled\"] %label": "color:[txtd];"
  }
}
 skins['wysiwyg.viewer.skins.button.ButtonLiftedShadow'] = {
  "react": [
    [
      "div",
      null,
      [
        "_left",
        "_shd"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_right",
        "_shd"
      ],
      {}
    ],
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "trans1": "TRANSITION",
    "shd": "BOX_SHADOW",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "fnt": "FONT",
    "trans2": "TRANSITION",
    "txt": "TEXT_COLOR",
    "tdr": "URL",
    "bgh": "BG_COLOR_ALPHA",
    "brdh": "BORDER_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "bgd": "BG_COLOR_ALPHA",
    "brdd": "BORDER_COLOR_ALPHA",
    "txtd": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "rd": "0",
    "bg": "color_17",
    "trans1": "border-color 0.4s ease 0s, background-color 0.4s ease 0s",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "brd": "color_15",
    "brw": "0",
    "fnt": "font_5",
    "trans2": "color 0.4s ease 0s",
    "txt": "color_15",
    "tdr": "BASE_THEME_DIRECTORY",
    "bgh": "color_18",
    "brdh": "color_15",
    "txth": "color_15",
    "bgd": "#CCCCCC",
    "brdd": "color_15",
    "txtd": "#FFFFFF"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%link": "[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  [shd]  border:solid [brd] [brw];",
    "%label": "[fnt]  [trans2]  color:[txt];display:inline-block;margin:calc(-1 * [brw]) [brw] 0;position:relative;white-space:nowrap;",
    "%_shd": "background:url([tdr]liftedshadow_medium.png) no-repeat;bottom:-26px;height:26px;position:absolute;width:165px;pointer-events:none;",
    "%_left": "background-position:0 0;left:-20px;",
    "%_right": "background-position:100% 0;right:-20px;",
    "%[data-disabled=\"false\"]": "cursor:pointer !important;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %link,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %link,%[data-disabled=\"false\"][data-preview~=\"hover\"] %link": "background-color:[bgh];border-color:[brdh];[trans1]",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %label,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %label,%[data-disabled=\"false\"][data-preview~=\"hover\"] %label": "color:[txth];[trans2]",
    "%[data-disabled=\"true\"] %link,%[data-preview~=\"disabled\"] %link": "background-color:[bgd];border:solid [brdd] [brw];",
    "%[data-disabled=\"true\"] %label,%[data-preview~=\"disabled\"] %label": "color:[txtd];"
  }
}
 skins['wysiwyg.viewer.skins.button.ButtonSandclock'] = {
  "react": [
    [
      "div",
      null,
      [
        "_wrapper"
      ],
      {},
      [
        "div",
        null,
        [
          "_container",
          "_top",
          "_left"
        ],
        {},
        [
          "div",
          null,
          [
            "_ribbon"
          ],
          {}
        ]
      ],
      [
        "div",
        null,
        [
          "_container",
          "_bottom",
          "_left"
        ],
        {},
        [
          "div",
          null,
          [
            "_ribbon"
          ],
          {}
        ]
      ],
      [
        "div",
        null,
        [
          "_container",
          "_top",
          "_right"
        ],
        {},
        [
          "div",
          null,
          [
            "_ribbon"
          ],
          {}
        ]
      ],
      [
        "div",
        null,
        [
          "_container",
          "_bottom",
          "_right"
        ],
        {},
        [
          "div",
          null,
          [
            "_ribbon"
          ],
          {}
        ]
      ],
      [
        "div",
        null,
        [
          "_filler"
        ],
        {}
      ]
    ],
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "bg": "BORDER_COLOR_ALPHA",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "txth": "TEXT_COLOR",
    "bgh": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "bg": "color_17",
    "fnt": "font_5",
    "txt": "color_15",
    "txth": "color_15",
    "bgh": "color_18"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%": "transform:rotate(360deg);",
    "%_wrapper": "position:absolute;top:0;right:0;bottom:0;left:0;width:50%;",
    "%_container": "position:absolute;height:50%;overflow:hidden;width:80%;",
    "%_right": "left:120%;",
    "%_bottom": "bottom:0;",
    "%_ribbon": "position:absolute;width:0;height:0;border-style:solid;",
    "%_right %_ribbon": "right:0;",
    "%_top %_ribbon": "top:0;",
    "%_bottom %_ribbon": "bottom:0;",
    "%_top%_left %_ribbon": "border-color:transparent [bg] transparent transparent;border-width:0 500px 500px 0;",
    "%_bottom%_left %_ribbon": "border-color:transparent transparent [bg] transparent;border-width:0 0 500px 500px;",
    "%_top%_right %_ribbon": "border-color:[bg] transparent transparent transparent;border-width:500px 500px 0 0;",
    "%_bottom%_right %_ribbon": "border-color:transparent transparent transparent [bg];border-width:500px 0 0 500px;",
    "%_filler": "position:absolute;width:50%;background-color:[bg];width:40%;height:100%;left:80%;",
    "%link": "position:absolute;top:0;right:0;bottom:0;left:0;overflow:hidden;height:100%;cursor:pointer !important;",
    "%label": "[fnt]  color:[txt];white-space:nowrap;display:inline-block;position:relative;",
    "%:active[data-state~=\"mobile\"] %label,%:hover[data-state~=\"desktop\"] %label,%[data-preview~=\"hover\"] %label": "color:[txth];",
    "%:active[data-state~=\"mobile\"] %_top%_left %_ribbon,%:hover[data-state~=\"desktop\"] %_top%_left %_ribbon,%[data-preview~=\"hover\"] %_top%_left %_ribbon": "border-right-color:[bgh];",
    "%:active[data-state~=\"mobile\"] %_bottom%_left %_ribbon,%:hover[data-state~=\"desktop\"] %_bottom%_left %_ribbon,%[data-preview~=\"hover\"] %_bottom%_left %_ribbon": "border-bottom-color:[bgh];",
    "%:active[data-state~=\"mobile\"] %_top%_right %_ribbon,%:hover[data-state~=\"desktop\"] %_top%_right %_ribbon,%[data-preview~=\"hover\"] %_top%_right %_ribbon": "border-top-color:[bgh];",
    "%:active[data-state~=\"mobile\"] %_bottom%_right %_ribbon,%:hover[data-state~=\"desktop\"] %_bottom%_right %_ribbon,%[data-preview~=\"hover\"] %_bottom%_right %_ribbon": "border-left-color:[bgh];",
    "%:active[data-state~=\"mobile\"] %_filler,%:hover[data-state~=\"desktop\"] %_filler,%[data-preview~=\"hover\"] %_filler": "background-color:[bgh];"
  }
}
 skins['wysiwyg.viewer.skins.button.ButtonShadowLeft'] = {
  "react": [
    [
      "div",
      null,
      [
        "_shd"
      ],
      {}
    ],
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "trans1": "TRANSITION",
    "shd": "BOX_SHADOW",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "fnt": "FONT",
    "trans2": "TRANSITION",
    "txt": "TEXT_COLOR",
    "tdr": "URL",
    "bgh": "BG_COLOR_ALPHA",
    "brdh": "BORDER_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "bgd": "BG_COLOR_ALPHA",
    "brdd": "BORDER_COLOR_ALPHA",
    "txtd": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "rd": "0",
    "bg": "color_17",
    "trans1": "border-color 0.4s ease 0s, background-color 0.4s ease 0s",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "brd": "color_15",
    "brw": "0",
    "fnt": "font_5",
    "trans2": "color 0.4s ease 0s",
    "txt": "color_15",
    "tdr": "BASE_THEME_DIRECTORY",
    "bgh": "color_18",
    "brdh": "color_15",
    "txth": "color_15",
    "bgd": "#CCCCCC",
    "brdd": "color_15",
    "txtd": "#FFFFFF"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%link": "[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  [shd]  border:solid [brd] [brw];",
    "%label": "[fnt]  [trans2]  color:[txt];display:inline-block;margin:calc(-1 * [brw]) [brw] 0;position:relative;white-space:nowrap;",
    "%_shd": "background:url([tdr]liftedshadow_medium.png) no-repeat 0 0;bottom:-26px;height:26px;left:-20px;position:absolute;width:165px;",
    "%[data-disabled=\"false\"]": "cursor:pointer !important;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %link,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %link,%[data-disabled=\"false\"][data-preview~=\"hover\"] %link": "background-color:[bgh];border:solid [brdh] [brw];[trans1]",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %label,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %label,%[data-disabled=\"false\"][data-preview~=\"hover\"] %label": "color:[txth];[trans2]",
    "%[data-disabled=\"true\"] %link,%[data-preview~=\"disabled\"] %link": "background-color:[bgd];border:solid [brdd] [brw];",
    "%[data-disabled=\"true\"] %label,%[data-preview~=\"disabled\"] %label": "color:[txtd];"
  }
}
 skins['wysiwyg.viewer.skins.button.ButtonShadowRight'] = {
  "react": [
    [
      "div",
      null,
      [
        "_shd"
      ],
      {}
    ],
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "trans1": "TRANSITION",
    "shd": "BOX_SHADOW",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "fnt": "FONT",
    "trans2": "TRANSITION",
    "txt": "TEXT_COLOR",
    "tdr": "URL",
    "bgh": "BG_COLOR_ALPHA",
    "brdh": "BORDER_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "bgd": "BG_COLOR_ALPHA",
    "brdd": "BORDER_COLOR_ALPHA",
    "txtd": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "rd": "0",
    "bg": "color_17",
    "trans1": "border-color 0.4s ease 0s, background-color 0.4s ease 0s",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "brd": "color_15",
    "brw": "0",
    "fnt": "font_5",
    "trans2": "color 0.4s ease 0s",
    "txt": "color_15",
    "tdr": "BASE_THEME_DIRECTORY",
    "bgh": "color_18",
    "brdh": "color_15",
    "txth": "color_15",
    "bgd": "#CCCCCC",
    "brdd": "color_15",
    "txtd": "#FFFFFF"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%link": "[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  [shd]  border:solid [brd] [brw];",
    "%label": "[fnt]  [trans2]  color:[txt];display:inline-block;margin:calc(-1 * [brw]) [brw] 0;position:relative;white-space:nowrap;",
    "%_shd": "background:url([tdr]liftedshadow_medium.png) no-repeat 100% 0;bottom:-26px;height:26px;position:absolute;right:-20px;width:165px;",
    "%[data-disabled=\"false\"]": "cursor:pointer !important;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %link,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %link,%[data-disabled=\"false\"][data-preview~=\"hover\"] %link": "background-color:[bgh];border:solid [brdh] [brw];[trans1]",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %label,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %label,%[data-disabled=\"false\"][data-preview~=\"hover\"] %label": "color:[txth];[trans2]",
    "%[data-disabled=\"true\"] %link,%[data-preview~=\"disabled\"] %link": "background-color:[bgd];border:solid [brdd] [brw];",
    "%[data-disabled=\"true\"] %label,%[data-preview~=\"disabled\"] %label": "color:[txtd];"
  }
}
 skins['wysiwyg.viewer.skins.button.CircleButton'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "trans1": "TRANSITION",
    "shd": "BOX_SHADOW",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "rd": "BORDER_RADIUS",
    "fnt": "FONT",
    "trans2": "TRANSITION",
    "txt": "TEXT_COLOR",
    "bgh": "BG_COLOR_ALPHA",
    "brdh": "BORDER_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "bgd": "BG_COLOR_ALPHA",
    "brdd": "BORDER_COLOR_ALPHA",
    "txtd": "TEXT_COLOR",
    "s": "SIZE"
  },
  "paramsDefaults": {
    "bg": "color_17",
    "trans1": "border 0.4s ease 0s, background-color 0.4s ease 0s",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "brd": "color_15",
    "brw": "0",
    "rd": "50%",
    "fnt": "font_5",
    "trans2": "color 0.4s ease 0s",
    "txt": "color_15",
    "bgh": "color_18",
    "brdh": "color_15",
    "txth": "color_15",
    "bgd": "#CCCCCC",
    "brdd": "color_15",
    "txtd": "#FFFFFF",
    "s": [
      "brw"
    ]
  },
  "paramsMutators": {
    "s": {
      "type": "decrease",
      "value": 5,
      "param": "brw"
    }
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%link": "position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  [shd]  border:solid [brd] [brw];[rd]",
    "%label": "[fnt]  [trans2]  color:[txt];display:inline-block;margin:calc(-1 * [brw]) [brw] 0;position:relative;white-space:nowrap;",
    "%[data-disabled=\"false\"]": "cursor:pointer !important;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %link,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %link,%[data-disabled=\"false\"][data-preview~=\"hover\"] %link": "background-color:[bgh];border:solid [brdh] [s];[trans1]",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %label,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %label,%[data-disabled=\"false\"][data-preview~=\"hover\"] %label": "color:[txth];[trans2]",
    "%[data-disabled=\"true\"] %link,%[data-preview~=\"disabled\"] %link": "background-color:[bgd];border:solid [brdd] [brw];",
    "%[data-disabled=\"true\"] %label,%[data-preview~=\"disabled\"] %label": "color:[txtd];"
  }
}
 skins['wysiwyg.viewer.skins.button.ButtonThreeD'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "trans": "TRANSITION",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "bgh": "BG_COLOR_ALPHA",
    "brdh": "BORDER_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "bgd": "BG_COLOR_ALPHA",
    "brdd": "BORDER_COLOR_ALPHA",
    "txtd": "TEXT_COLOR",
    "shc": "COLOR"
  },
  "paramsDefaults": {
    "rd": "5px",
    "bg": "color_17",
    "brd": "color_15",
    "brw": "0",
    "trans": "all 0.12s ease 0s",
    "fnt": "font_5",
    "txt": "color_15",
    "bgh": "color_18",
    "brdh": "color_15",
    "txth": "color_15",
    "bgd": "#CCCCCC",
    "brdd": "color_15",
    "txtd": "#FFFFFF",
    "shc": [
      "bg"
    ]
  },
  "paramsMutators": {
    "shc": {
      "type": "brightness",
      "value": 0.5,
      "param": "bg"
    }
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%link": "[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];border:solid [brd] [brw];box-shadow:1px 1px [shc], 3px 3px [shc], 5px 5px [shc], 7px 7px [shc], 9px 9px [shc];[trans]",
    "%label": "[fnt]  [trans]  color:[txt];display:inline-block;margin-top:calc(-1 * [brw]);position:relative;white-space:nowrap;",
    "%[data-state~=\"shouldUseFlex\"] %label": "margin-top:0;",
    "%[data-disabled=\"false\"]": "cursor:pointer !important;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %link,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %link,%[data-disabled=\"false\"][data-preview~=\"hover\"] %link": "background-color:[bgh];border:solid [brdh] [brw];bottom:-9px;box-shadow:0 0 [shc];left:9px;right:-9px;top:9px;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %label,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %label,%[data-disabled=\"false\"][data-preview~=\"hover\"] %label": "color:[txth];",
    "%[data-disabled=\"true\"] %link,%[data-preview~=\"disabled\"] %link": "background-color:[bgd];border:solid [brdd] [brw];",
    "%[data-disabled=\"true\"] %label,%[data-preview~=\"disabled\"] %label": "color:[txtd];"
  }
}
 skins['wysiwyg.viewer.skins.button.DisabledLayerButton'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "trans1": "TRANSITION",
    "shd": "BOX_SHADOW",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "fnt": "FONT",
    "trans2": "TRANSITION",
    "txt": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "rd": "0",
    "bg": "color_17",
    "trans1": "border-color 0.4s ease 0s, background-color 0.4s ease 0s",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "brd": "color_15",
    "brw": "0",
    "fnt": "font_5",
    "trans2": "color 0.4s ease 0s",
    "txt": "color_15"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%link": "[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  [shd]  border:solid [brd] [brw];cursor:pointer !important;",
    "%label": "[fnt]  [trans2]  color:[txt];white-space:nowrap;margin:1px [brw] 0 [brw];display:inline-block;position:relative;",
    "%:active[data-state~=\"mobile\"] %link,%:hover[data-state~=\"desktop\"] %link,%[data-preview~=\"hover\"] %link": "[trans1]",
    "%:active[data-state~=\"mobile\"] %label,%:hover[data-state~=\"desktop\"] %label,%[data-preview~=\"hover\"] %label": "[trans2]",
    "%[data-disabled~=\"disabled\"] %link": "opacity:0.5;cursor:not-allowed !important;"
  }
}
 skins['wysiwyg.viewer.skins.button.EcomFeedbackCheckoutButton'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "trans1": "TRANSITION",
    "brd": "BORDER_COLOR",
    "brw": "BORDER_SIZE",
    "fnt": "FONT",
    "trans2": "TRANSITION",
    "txt": "TEXT_COLOR",
    "bgh": "BG_COLOR_ALPHA",
    "brdh": "BORDER_COLOR",
    "txth": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "rd": "0",
    "bg": "color_15",
    "trans1": "border-color 0.4s ease 0s, background-color 0.4s ease 0s",
    "brd": "color_15",
    "brw": "0",
    "fnt": "font_8",
    "trans2": "color 0.4s ease 0s",
    "txt": "color_11",
    "bgh": "color_14",
    "brdh": "color_14",
    "txth": "color_11"
  },
  "css": {
    "%link": "[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  border:solid [brd] [brw];cursor:pointer !important;",
    "%label": "[fnt]  [trans2]  color:[txt];white-space:nowrap;margin:calc(-1 * [brw]) [brw] 0 [brw];display:inline-block;position:relative;",
    "%:hover %link": "background-color:[bgh];border-color:[brdh];[trans1]",
    "%:hover %label": "color:[txth];[trans2]"
  }
}
 skins['wysiwyg.viewer.skins.button.EcomFeedbackContinueShopping'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "trans1": "TRANSITION",
    "brd": "BORDER_COLOR",
    "brw": "BORDER_SIZE",
    "fnt": "FONT",
    "trans2": "TRANSITION",
    "txt": "TEXT_COLOR",
    "bgh": "BG_COLOR_ALPHA",
    "brdh": "BORDER_COLOR",
    "txth": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "rd": "0",
    "bg": "color_14",
    "trans1": "border-color 0.4s ease 0s, background-color 0.4s ease 0s",
    "brd": "color_14",
    "brw": "0",
    "fnt": "font_8",
    "trans2": "color 0.4s ease 0s",
    "txt": "color_11",
    "bgh": "color_13",
    "brdh": "color_13",
    "txth": "color_11"
  },
  "css": {
    "%link": "[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  border:solid [brd] [brw];cursor:pointer !important;",
    "%label": "[fnt]  [trans2]  color:[txt];white-space:nowrap;margin:calc(-1 * [brw]) [brw] 0 [brw];display:inline-block;position:relative;",
    "%:hover %link": "background-color:[bgh];border-color:[brdh];[trans1]",
    "%:hover %label": "color:[txth];[trans2]"
  }
}
 skins['wysiwyg.viewer.skins.button.EcomFeedbackContinueShopping2'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "trans1": "TRANSITION",
    "brd": "BORDER_COLOR",
    "brw": "BORDER_SIZE",
    "fnt": "FONT",
    "trans2": "TRANSITION",
    "txt": "TEXT_COLOR",
    "bgh": "BG_COLOR_ALPHA",
    "brdh": "BORDER_COLOR",
    "txth": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "rd": "0",
    "bg": "color_11",
    "trans1": "border-color 0.4s ease 0s, background-color 0.4s ease 0s",
    "brd": "color_11",
    "brw": "0",
    "fnt": "font_8",
    "trans2": "color 0.4s ease 0s",
    "txt": "color_15",
    "bgh": "color_11",
    "brdh": "color_11",
    "txth": "color_14"
  },
  "css": {
    "%link": "[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  border:solid [brd] [brw];cursor:pointer !important;",
    "%label": "[fnt]  [trans2]  color:[txt];white-space:nowrap;margin:calc(-1 * [brw]) [brw] 0 [brw];display:inline-block;position:relative;",
    "%:hover %link": "background-color:[bgh];border-color:[brdh];[trans1]",
    "%:hover %label": "color:[txth];[trans2]"
  }
}
 skins['wysiwyg.viewer.skins.button.FixedFontButton'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "txt": "TEXT_COLOR",
    "brd": "BORDER_COLOR",
    "brw": "BORDER_SIZE",
    "txth": "TEXT_COLOR",
    "brdh": "BORDER_COLOR",
    "bgh": "BG_COLOR_ALPHA",
    "trans": "TRANSITION"
  },
  "paramsDefaults": {
    "bg": "#ffffff",
    "rd": "0",
    "txt": "#000000",
    "brd": "#000000",
    "brw": "0",
    "txth": "#000000",
    "brdh": "#000000",
    "bgh": "#ffffff",
    "trans": "color 0.4s ease 0s, border-color 0.4s ease 0s, background-color 0.4s ease 0s"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%link": "position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[rd]  color:[txt];border:solid [brd] [brw];cursor:pointer !important;",
    "%label": "display:block;margin:-10px 0 0 -10px;text-align:center;width:20px;height:20px;line-height:20px;position:absolute;top:50%;left:50%;white-space:nowrap;font-size:12px !important;font-family:Arial;",
    "%:active[data-state~=\"mobile\"] %link,%:hover[data-state~=\"desktop\"] %link,%[data-preview~=\"hover\"] %link": "color:[txth];border-color:[brdh];background-color:[bgh];[trans]",
    "%:active[data-state~=\"mobile\"] %label,%:hover[data-state~=\"desktop\"] %label,%[data-preview~=\"hover\"] %label": "color:[txth];[trans]",
    "%_justx": "width:20px;height:20px;",
    "%_justx %link": "font-size:16px !important;",
    "%_circle": "width:20px;height:20px;",
    "%_circle %link": "border-radius:50% !important;"
  }
}
 skins['wysiwyg.viewer.skins.button.GamingButton'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        null,
        [
          "_left"
        ],
        {}
      ],
      [
        "span",
        null,
        [
          "_right"
        ],
        {}
      ],
      [
        "span",
        "label",
        [],
        {}
      ],
      [
        "div",
        null,
        [
          "_shineOnYouCrazyDiamond"
        ],
        {}
      ]
    ]
  ],
  "params": {
    "bgh": "BG_COLOR_ALPHA",
    "trans1": "TRANSITION",
    "txth": "TEXT_COLOR",
    "rd": "BORDER_RADIUS",
    "tdr": "URL",
    "bg": "BG_COLOR_ALPHA",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "bgd": "BG_COLOR_ALPHA",
    "txtd": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "bgh": "color_18",
    "trans1": "background-color 0.4s ease 0s",
    "txth": "color_15",
    "rd": "12px",
    "tdr": "BASE_THEME_DIRECTORY",
    "bg": "color_17",
    "fnt": "font_6",
    "txt": "color_15",
    "bgd": "#CCCCCC",
    "txtd": "#FFFFFF"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%[data-disabled=\"false\"]": "overflow:visible !important;cursor:pointer !important;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %link,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %link,%[data-disabled=\"false\"][data-preview~=\"hover\"] %link": "background-color:[bgh];[trans1]",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %label,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %label,%[data-disabled=\"false\"][data-preview~=\"hover\"] %label": "color:[txth];",
    "%link": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  [trans1]  background:url([tdr]gaming_pattern.png) repeat 0 0;background-color:[bg];border:solid #000 2px;box-shadow:rgba(255, 255, 255, 0.3) 0 3px 6px 0 inset, rgba(0, 0, 0, 0.6) -2px -3px 6px 0 inset, rgba(0, 0, 0, 0.6) 1px 1px 6px 1px, rgba(255, 255, 255, 0.3) 1px 1px 3px 0;width:100%;",
    "%_left,%_right": "background-position:0 center;background-repeat:no-repeat;height:100%;position:absolute;width:66px;",
    "%_left": "background-image:url([tdr]darknessleft.png);border-radius:10px 0 0 10px;left:0;",
    "%_right": "background-image:url([tdr]darknessright.png);border-radius:0 10px 10px 0;right:0;",
    "%label": "[fnt]  color:[txt];display:inline-block;margin-top:-1px;position:relative;white-space:nowrap;",
    "%_shineOnYouCrazyDiamond": "bottom:50%;top:0;left:0;background:url([tdr]whitegradient.png) repeat-x 0 100%;overflow:hidden;border-radius:12px 12px 0 0;width:100%;content:'';position:absolute;",
    "%[data-disabled=\"true\"] %link,%[data-preview~=\"disabled\"] %link": "background-color:[bgd];",
    "%[data-disabled=\"true\"] %label,%[data-preview~=\"disabled\"] %label": "color:[txtd];"
  }
}
 skins['wysiwyg.viewer.skins.button.IronButton'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        null,
        [
          "_glow"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_screw-tl",
          "_screw"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_screw-tr",
          "_screw"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_screw-bt",
          "_screw"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_screw-br",
          "_screw"
        ],
        {}
      ],
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "trans1": "TRANSITION",
    "shd": "BOX_SHADOW",
    "tdr": "URL",
    "txt": "TEXT_COLOR",
    "ishd": "BOX_SHADOW",
    "fnt": "FONT",
    "bgh": "BG_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "bgd": "BG_COLOR_ALPHA",
    "txtd": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "rd": "2",
    "bg": "color_17",
    "trans1": "border-color 0.4s ease 0s, background-color 0.4s ease 0s, color 0.4s ease 0s",
    "shd": "0 2px 5px rgba(0, 0, 0, 0.53)",
    "tdr": "BASE_THEME_DIRECTORY",
    "txt": "color_15",
    "ishd": "rgba(255, 255, 255, 0.59) 0 0 6px 0 inset, rgba(255, 255, 255, 0.92) 0 1px 0 0 inset, rgba(255, 255, 255, 0.2) 0 0 5px 0 inset",
    "fnt": "font_5",
    "bgh": "color_17",
    "txth": "color_15",
    "bgd": "#CCCCCC",
    "txtd": "#FFFFFF"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%link": "[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  [shd]  background-image:url([tdr]ironpatern.png);color:[txt];",
    "%_glow": "position:absolute;top:0;right:0;bottom:0;left:0;[ishd]  [rd]",
    "%label": "[fnt]  [trans1]  color:[txt];display:inline-block;position:relative;white-space:nowrap;",
    "%_screw": "width:15px;height:15px;background-image:url([tdr]skrew.png);background-repeat:no-repeat;display:inline-block;",
    "%_screw-tl,%_screw-tr,%_screw-bt,%_screw-br": "position:absolute;",
    "%_screw-tl": "left:2px;top:2px;",
    "%_screw-tr": "right:2px;top:2px;",
    "%_screw-bt": "bottom:2px;left:2px;",
    "%_screw-br": "bottom:2px;right:2px;",
    "%[data-disabled=\"false\"]": "cursor:pointer !important;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %link,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %link,%[data-disabled=\"false\"][data-preview~=\"hover\"] %link": "background-color:[bgh];",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %label,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %label,%[data-disabled=\"false\"][data-preview~=\"hover\"] %label": "color:[txth];",
    "%[data-disabled=\"true\"] %link,%[data-preview~=\"disabled\"] %link": "background-color:[bgd];",
    "%[data-disabled=\"true\"] %label,%[data-preview~=\"disabled\"] %label": "color:[txtd];"
  }
}
 skins['wysiwyg.viewer.skins.button.PillButton'] = {
  "react": [
    [
      "a",
      "link",
      [
        "_lnk"
      ],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "xxx": "BG_COLOR_ALPHA",
    "tdr": "URL",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "bgh": "BG_COLOR_ALPHA",
    "txth": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "bg": "color_1",
    "rd": "80px",
    "xxx": "color_1",
    "tdr": "BASE_THEME_DIRECTORY",
    "fnt": "font_5",
    "txt": "color_5",
    "bgh": "color_2",
    "txth": "color_6"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%link": "position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[rd]  background:[xxx] url([tdr]net.png) center center repeat;",
    "%label": "[fnt]  color:[txt];white-space:nowrap;padding:0 10px;",
    "%:active[data-state~=\"mobile\"] %link,%:hover[data-state~=\"desktop\"] %link,%[data-preview~=\"hover\"] %link": "background-color:[bgh];",
    "%:active[data-state~=\"mobile\"] %label,%:hover[data-state~=\"desktop\"] %label,%[data-preview~=\"hover\"] %label": "color:[txth];"
  }
}
 skins['wysiwyg.viewer.skins.button.PlasticButton'] = {
  "react": [
    [
      "div",
      null,
      [
        "_pose",
        "_mainColor"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_pose",
        "_grd1"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_pose",
        "_topper"
      ],
      {}
    ],
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ],
    [
      "div",
      null,
      [
        "_pose",
        "_bord"
      ],
      {}
    ]
  ],
  "exports": {
    "_maxWidth": 400,
    "_maxHeight": 400
  },
  "params": {
    "txt": "TEXT_COLOR",
    "fnt": "FONT",
    "txth": "TEXT_COLOR",
    "bgh": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "xxx": "BG_COLOR_ALPHA",
    "tdr": "URL"
  },
  "paramsDefaults": {
    "txt": "color_5",
    "fnt": "font_5",
    "txth": "color_6",
    "bgh": "color_2",
    "rd": "15px",
    "bg": "color_1",
    "xxx": "color_1",
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%": "text-align:center;",
    "%label": "position:relative;color:[txt];[fnt]  white-space:nowrap;padding:15px 10px;",
    "%:active[data-state~=\"mobile\"] %label,%:hover[data-state~=\"desktop\"] %label,%[data-preview~=\"hover\"] %label": "color:[txth];",
    "%:active[data-state~=\"mobile\"] %_mainColor,%:hover[data-state~=\"desktop\"] %_mainColor,%[data-preview~=\"hover\"] %_mainColor": "background-color:[bgh];",
    "%_pose": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]",
    "%_mainColor": "background-color:[bg];background:[xxx] url([tdr]net.png) center center repeat;",
    "%_topper": "background:url([tdr]transparent_arc.png) center center;",
    "%_oval": "background:#00f;width:200%;height:50%;",
    "%_bord": "border-width:11px;border-image:url([tdr]plastic_highlight_border.png) 11 stretch stretch;"
  }
}
 skins['wysiwyg.viewer.skins.button.RibbonButton'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ],
    [
      "div",
      null,
      [
        "_tail"
      ],
      {},
      [
        "div",
        null,
        [
          "_tri"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_tri"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_tri"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_tri"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_tri"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_tri"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_tri"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_tri"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_tri"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_tri"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_tri"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_tri"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_tri"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_tri"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_tri"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_tri"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_tri"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_tri"
        ],
        {}
      ]
    ]
  ],
  "exports": {
    "maxH": 170
  },
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "bgh": "BG_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "clrh": "COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "clr": "COLOR_ALPHA",
    "bgd": "BG_COLOR_ALPHA",
    "txtd": "TEXT_COLOR",
    "clrd": "COLOR_ALPHA"
  },
  "paramsDefaults": {
    "bg": "color_17",
    "bgh": "color_18",
    "txth": "color_15",
    "clrh": [
      "bgh"
    ],
    "brw": "0",
    "fnt": "font_5",
    "txt": "color_15",
    "clr": [
      "bg"
    ],
    "bgd": "#CCCCCC",
    "txtd": "#FFFFFF",
    "clrd": [
      "bgd"
    ]
  },
  "css": {
    "% button%link": "width:100%;width:calc(100% - 5px);",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%link": "background-color:[bg];position:absolute;top:0;right:5px;bottom:0;left:0;",
    "%[data-disabled=\"false\"]": "cursor:pointer !important;max-height:170px !important;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %link,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %link,%[data-disabled=\"false\"][data-preview~=\"hover\"] %link": "background-color:[bgh];",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %label,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %label,%[data-disabled=\"false\"][data-preview~=\"hover\"] %label": "color:[txth];",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %_tri,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %_tri,%[data-disabled=\"false\"][data-preview~=\"hover\"] %_tri": "border-left-color:[clrh];border:solid [brw];",
    "%label": "[fnt]  color:[txt];display:inline-block;max-height:170px !important;padding:5px 10px;white-space:nowrap;",
    "%_tail": "overflow:hidden;width:5px;position:absolute;top:0;right:0;bottom:0;left:auto;",
    "%_tri": "border:solid 5px transparent;border-left-color:[clr];",
    "%_tri:first-child": "margin-top:-5px;",
    "%[data-disabled=\"true\"] %link,%[data-preview~=\"disabled\"] %link": "background-color:[bgd];",
    "%[data-disabled=\"true\"] %label,%[data-preview~=\"disabled\"] %label": "color:[txtd];",
    "%[data-disabled=\"true\"] %_tri,%[data-preview~=\"disabled\"] %_tri": "border-left-color:[clrd];border:solid [brw];"
  }
}
 skins['wysiwyg.viewer.skins.button.ScotchTapeButton'] = {
  "react": [
    [
      "div",
      null,
      [
        "_left"
      ],
      {}
    ],
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ],
    [
      "div",
      null,
      [
        "_right"
      ],
      {}
    ]
  ],
  "params": {
    "txth": "TEXT_COLOR",
    "trans2": "TRANSITION",
    "tdr": "URL",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "txtd": "TEXT_COLOR",
    "brd2": "COLOR_ALPHA",
    "bg": "COLOR_ALPHA",
    "brd": "COLOR_ALPHA"
  },
  "paramsDefaults": {
    "txth": "color_15",
    "trans2": "color 0.4s ease 0s",
    "tdr": "BASE_THEME_DIRECTORY",
    "fnt": "font_5",
    "txt": "color_15",
    "txtd": "#FFFFFF",
    "brd2": "#fff",
    "bg": "#000",
    "brd": "#000"
  },
  "paramsMutators": {
    "brd2": {
      "type": "alpha",
      "value": 0.12
    },
    "bg": {
      "type": "alpha",
      "value": 0.01
    },
    "brd": {
      "type": "alpha",
      "value": 0.13
    }
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%:before,%:after": "border-bottom:solid 1px [brd2];content:'';display:block;height:7px;left:1px;position:absolute;right:1px;",
    "%:before": "top:-7px;",
    "%:after": "bottom:-7px;",
    "%[data-disabled=\"false\"]": "cursor:pointer;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %label,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %label,%[data-disabled=\"false\"][data-preview~=\"hover\"] %label": "color:[txth];[trans2]",
    "%link": "position:absolute;top:0;right:0;bottom:0;left:0;background:[bg];border-bottom:solid 1px [brd];border-top:solid 1px [brd];display:block;",
    "%_left,%_right": "background:transparent url([tdr]scotchtape_edges.png) repeat-y;bottom:0;content:'';display:block;position:absolute;top:0;width:10px;",
    "%_left": "background-position:right -2px;left:-7px;",
    "%_right": "background-position:left -2px;right:-7px;",
    "%label": "[fnt]  [trans2]  color:[txt];display:inline-block;position:relative;white-space:nowrap;",
    "%[data-disabled=\"true\"] %label,%[data-preview~=\"disabled\"] %label": "color:[txtd];"
  }
}
 skins['wysiwyg.viewer.skins.button.ShineButton'] = {
  "react": [
    [
      "a",
      "link",
      [
        "_lnk"
      ],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "tdr": "URL",
    "xxx": "BG_COLOR_ALPHA",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "bgh": "BG_COLOR_ALPHA",
    "txth": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "bg": "color_1",
    "rd": "5px",
    "tdr": "BASE_THEME_DIRECTORY",
    "xxx": "color_1",
    "fnt": "font_5",
    "txt": "color_5",
    "bgh": "color_2",
    "txth": "color_6"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%link": "position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[rd]  background-image:url([tdr]apple_menu.png);background-position:0 50%;background-repeat:repeat-x;background:[xxx] url([tdr]net.png) center center repeat;",
    "%label": "[fnt]  color:[txt];white-space:nowrap;padding:0 10px;",
    "%:active[data-state~=\"mobile\"] %link,%:hover[data-state~=\"desktop\"] %link,%[data-preview~=\"hover\"] %link": "background-color:[bgh];",
    "%:active[data-state~=\"mobile\"] %label,%:hover[data-state~=\"desktop\"] %label,%[data-preview~=\"hover\"] %label": "color:[txth];"
  }
}
 skins['wysiwyg.viewer.skins.button.ShinyButtonIISkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "trans1": "TRANSITION",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "tdr": "URL",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "fnt": "FONT",
    "trans2": "TRANSITION",
    "txt": "TEXT_COLOR",
    "bgh": "BG_COLOR_ALPHA",
    "brdh": "BORDER_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "bgd": "BG_COLOR_ALPHA",
    "brdd": "BORDER_COLOR_ALPHA",
    "txtd": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "rd": "5px",
    "trans1": "border-color 0.4s ease 0s, background-color 0.4s ease 0s",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "bg": "color_17",
    "tdr": "BASE_THEME_DIRECTORY",
    "brd": "color_15",
    "brw": "0",
    "fnt": "font_5",
    "trans2": "color 0.4s ease 0s",
    "txt": "color_15",
    "bgh": "color_18",
    "brdh": "color_15",
    "txth": "color_15",
    "bgd": "#CCCCCC",
    "brdd": "color_15",
    "txtd": "#FFFFFF"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%link": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  [trans1]  [shd]  background:[bg] url([tdr]shiny2button_bg.png) center top repeat-x;border:solid [brd] [brw];",
    "%label": "[fnt]  [trans2]  color:[txt];display:inline-block;margin-top:calc(-1 * [brw]);position:relative;white-space:nowrap;",
    "%[data-state~=\"shouldUseFlex\"] %label": "margin-top:0;",
    "%[data-disabled=\"false\"]": "cursor:pointer !important;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %link,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %link,%[data-disabled=\"false\"][data-preview~=\"hover\"] %link": "background-color:[bgh];border:solid [brdh] [brw];",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %label,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %label,%[data-disabled=\"false\"][data-preview~=\"hover\"] %label": "color:[txth];",
    "%[data-disabled=\"true\"] %link,%[data-preview~=\"disabled\"] %link": "background-color:[bgd];border:solid [brdd] [brw];",
    "%[data-disabled=\"true\"] %label,%[data-preview~=\"disabled\"] %label": "color:[txtd];"
  }
}
 skins['wysiwyg.viewer.skins.button.ShinyButtonISkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "trans1": "TRANSITION",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "tdr": "URL",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "fnt": "FONT",
    "trans2": "TRANSITION",
    "txt": "TEXT_COLOR",
    "bgh": "BG_COLOR_ALPHA",
    "brdh": "BORDER_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "bgd": "BG_COLOR_ALPHA",
    "brdd": "BORDER_COLOR_ALPHA",
    "txtd": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "rd": "5px",
    "trans1": "border-color 0.4s ease 0s, background-color 0.4s ease 0s",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "bg": "color_17",
    "tdr": "BASE_THEME_DIRECTORY",
    "brd": "color_15",
    "brw": "0",
    "fnt": "font_5",
    "trans2": "color 0.4s ease 0s",
    "txt": "color_15",
    "bgh": "color_18",
    "brdh": "color_15",
    "txth": "color_15",
    "bgd": "#CCCCCC",
    "brdd": "color_15",
    "txtd": "#FFFFFF"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%link": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  [trans1]  [shd]  background:[bg] url([tdr]shiny1button_bg.png) 50% 50% repeat-x;border:solid [brd] [brw];",
    "%label": "[fnt]  [trans2]  color:[txt];display:inline-block;margin-top:calc(-1 * [brw]);position:relative;white-space:nowrap;",
    "%[data-state~=\"shouldUseFlex\"] %label": "margin-top:0;",
    "%[data-disabled=\"false\"]": "cursor:pointer !important;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %link,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %link,%[data-disabled=\"false\"][data-preview~=\"hover\"] %link": "[trans1]    background-color:[bgh];border:solid [brdh] [brw];",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %label,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %label,%[data-disabled=\"false\"][data-preview~=\"hover\"] %label": "[trans2]    color:[txth];",
    "%[data-disabled=\"true\"] %link,%[data-preview~=\"disabled\"] %link": "background-color:[bgd];border:solid [brdd] [brw];",
    "%[data-disabled=\"true\"] %label,%[data-preview~=\"disabled\"] %label": "color:[txtd];"
  }
}
 skins['wysiwyg.viewer.skins.button.ShinyButtonInverted'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "labelwrapper",
        [],
        {},
        [
          "span",
          "label",
          [],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "trans1": "TRANSITION",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "tdr": "URL",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "fnt": "FONT",
    "trans2": "TRANSITION",
    "txt": "TEXT_COLOR",
    "bgh": "BG_COLOR_ALPHA",
    "brdh": "BORDER_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "bgd": "BG_COLOR_ALPHA",
    "brdd": "BORDER_COLOR_ALPHA",
    "txtd": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "rd": "5px",
    "trans1": "border-color 0.4s ease 0s, background-color 0.4s ease 0s",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "bg": "color_17",
    "tdr": "BASE_THEME_DIRECTORY",
    "brd": "color_15",
    "brw": "0",
    "fnt": "font_5",
    "trans2": "color 0.4s ease 0s",
    "txt": "color_15",
    "bgh": "color_18",
    "brdh": "color_15",
    "txth": "color_15",
    "bgd": "#CCCCCC",
    "brdd": "color_15",
    "txtd": "#FFFFFF"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%link": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  [trans1]  [shd]  background-color:[bg];background-image:url([tdr]indented_bg.png);background-position:0 0;background-repeat:repeat-x;border:solid [brd] [brw];",
    "%labelwrapper": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  background:url([tdr]indented_bg.png) 0 100% repeat-x;",
    "%label": "[fnt]  [trans2]  color:[txt];display:inline-block;margin-top:calc(-1 * [brw]);position:relative;white-space:nowrap;",
    "%[data-state~=\"shouldUseFlex\"] %label": "margin-top:0;",
    "%[data-disabled=\"false\"]": "cursor:pointer !important;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %link,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %link,%[data-disabled=\"false\"][data-preview~=\"hover\"] %link": "background-color:[bgh];border:solid [brdh] [brw];",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %label,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %label,%[data-disabled=\"false\"][data-preview~=\"hover\"] %label": "[trans2]    color:[txth];",
    "%[data-disabled=\"true\"] %link,%[data-preview~=\"disabled\"] %link": "background-color:[bgd];border:solid [brdd] [brw];",
    "%[data-disabled=\"true\"] %label,%[data-preview~=\"disabled\"] %label": "color:[txtd];"
  }
}
 skins['wysiwyg.viewer.skins.button.ShinyGradientButton'] = {
  "react": [
    [
      "div",
      null,
      [
        "_pose",
        "_mainColor"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_pose",
        "_grd1"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_pose",
        "_topper"
      ],
      {}
    ],
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ],
    [
      "div",
      null,
      [
        "_pose",
        "_bord"
      ],
      {}
    ]
  ],
  "exports": {
    "_maxWidth": 400,
    "_maxHeight": 400
  },
  "params": {
    "txt": "TEXT_COLOR",
    "fnt": "FONT",
    "txth": "TEXT_COLOR",
    "bgh": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "xxx": "BG_COLOR_ALPHA",
    "tdr": "URL"
  },
  "paramsDefaults": {
    "txt": "color_5",
    "fnt": "font_5",
    "txth": "color_6",
    "bgh": "color_2",
    "rd": "15px",
    "xxx": "color_1",
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%": "text-align:center;",
    "%label": "position:relative;color:[txt];[fnt]  white-space:nowrap;padding:15px 10px;display:inline-block;",
    "%:active[data-state~=\"mobile\"] %label,%:hover[data-state~=\"desktop\"] %label,%[data-preview~=\"hover\"] %label": "color:[txth];",
    "%:active[data-state~=\"mobile\"] %_mainColor,%:hover[data-state~=\"desktop\"] %_mainColor,%[data-preview~=\"hover\"] %_mainColor": "background-color:[bgh];",
    "%_pose": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]",
    "%_mainColor": "background:[xxx] url([tdr]net.png) center center repeat;",
    "%_topper": "background:url([tdr]transparent_arc.png) center center;",
    "%_oval": "background:#00f;width:200%;height:50%;",
    "%_bord": "border-width:11px;border-image:url([tdr]plastic_highlight_border.png) 11 stretch stretch;"
  }
}
 skins['wysiwyg.viewer.skins.button.ShinyPillButton'] = {
  "react": [
    [
      "a",
      "link",
      [
        "_lnk"
      ],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "tdr": "URL",
    "xxx": "BG_COLOR_ALPHA",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "bgh": "BG_COLOR_ALPHA",
    "txth": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "bg": "color_1",
    "rd": "80px",
    "tdr": "BASE_THEME_DIRECTORY",
    "xxx": "color_1",
    "fnt": "font_5",
    "txt": "color_5",
    "bgh": "color_2",
    "txth": "color_6"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%link": "position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[rd]  background-image:url([tdr]apple_menu.png);background-position:0 50%;background-repeat:repeat-x;background:[xxx] url([tdr]net.png) center center repeat;",
    "%label": "[fnt]  color:[txt];white-space:nowrap;padding:0 10px;display:inline-block;",
    "%:active[data-state~=\"mobile\"] %link,%:hover[data-state~=\"desktop\"] %link,%[data-preview~=\"hover\"] %link": "background-color:[bgh];",
    "%:active[data-state~=\"mobile\"] %label,%:hover[data-state~=\"desktop\"] %label,%[data-preview~=\"hover\"] %label": "color:[txth];"
  }
}
 skins['wysiwyg.viewer.skins.button.SiteButtonBlueSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "borderColor": "BORDER_COLOR",
    "rd": "BORDER_RADIUS",
    "bgColor": "BG_COLOR_ALPHA",
    "borderColorOver": "BORDER_COLOR",
    "bgColorOver": "BG_COLOR_ALPHA",
    "shadowColor": "BOX_SHADOW_COLOR_ALPHA",
    "shadowInsetColorOver": "BOX_SHADOW_COLOR_ALPHA",
    "labelColor": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "borderColor": "#5b8fa7",
    "rd": "4px",
    "bgColor": "#2aa7ea",
    "borderColorOver": "#237aa7",
    "bgColorOver": "#179ce1",
    "shadowColor": "0, 0, 0, 0.2",
    "shadowInsetColorOver": "68, 175, 233, 0.7",
    "labelColor": "#ffffff"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%": "padding:0 8px;border:1px solid [borderColor];[rd]  background-color:[bgColor];position:relative;text-align:center;cursor:pointer;",
    "%:active[data-state~=\"mobile\"],%:hover[data-state~=\"desktop\"],%[data-preview~=\"hover\"]": "border:1px solid [borderColorOver];background:[bgColorOver] none;box-shadow:0 2px 3px 0 [shadowColor], 0 1px 2px 0 [shadowInsetColorOver] inset;",
    "%label": "color:[labelColor];height:1.8em;line-height:1.8em;display:inline-block;"
  }
}
 skins['wysiwyg.viewer.skins.button.SiteButtonSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "xxx": "BG_COLOR_ALPHA",
    "tdr": "URL",
    "fnt": "FONT",
    "clr": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "xxx": "color_1",
    "tdr": "BASE_THEME_DIRECTORY",
    "fnt": "font_5",
    "clr": "color_1"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%link": "position:absolute;top:0;right:0;bottom:0;left:0;background:[xxx] url([tdr]net.png) center center repeat;",
    "%label": "[fnt]  color:[clr];white-space:nowrap;display:inline-block;"
  }
}
 skins['wysiwyg.viewer.skins.button.SloopyButton'] = {
  "react": [
    [
      "div",
      null,
      [
        "_brd",
        "_top"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_brd",
        "_bot"
      ],
      {}
    ],
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "bgh": "BG_COLOR_ALPHA",
    "trans1": "TRANSITION",
    "txth": "TEXT_COLOR",
    "trans2": "TRANSITION",
    "tdr": "URL",
    "bg": "BG_COLOR_ALPHA",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "bgd": "BG_COLOR_ALPHA",
    "txtd": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "bgh": "color_18",
    "trans1": "border-color 0.4s ease 0s, background-color 0.4s ease 0s",
    "txth": "color_15",
    "trans2": "color 0.4s ease 0s",
    "tdr": "BASE_THEME_DIRECTORY",
    "bg": "color_17",
    "fnt": "font_5",
    "txt": "color_15",
    "bgd": "#CCCCCC",
    "txtd": "#FFFFFF"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%[data-disabled=\"false\"]": "overflow:visible !important;cursor:pointer !important;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %link,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %link,%[data-disabled=\"false\"][data-preview~=\"hover\"] %link": "background-color:[bgh];[trans1]",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %label,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %label,%[data-disabled=\"false\"][data-preview~=\"hover\"] %label": "color:[txth];[trans2]",
    "%_brd": "background:url([tdr]sloppyframe.png) no-repeat;position:absolute;",
    "%_top": "top:-4px;right:0;bottom:0;left:-4px;background-position:0 0;",
    "%_bot": "top:0;right:-4px;bottom:-4px;left:0;background-position:100% 100%;",
    "%link": "position:absolute;top:4px;right:4px;bottom:4px;left:4px;[trans1]  background-color:[bg];",
    "%label": "[fnt]  [trans2]  color:[txt];display:inline-block;margin:-4px 0 0 -4px;position:relative;white-space:nowrap;",
    "%[data-disabled=\"true\"] %link,%[data-preview~=\"disabled\"] %link": "background-color:[bgd];",
    "%[data-disabled=\"true\"] %label,%[data-preview~=\"disabled\"] %label": "color:[txtd];"
  }
}
 skins['wysiwyg.viewer.skins.button.TextOnlyButtonSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "txth": "TEXT_COLOR",
    "trans": "TRANSITION",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "txtd": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "txth": "color_15",
    "trans": "color 0.4s ease 0s",
    "fnt": "font_5",
    "txt": "color_15",
    "txtd": "#FFFFFF"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%[data-disabled=\"false\"]": "cursor:pointer;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %label,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %label,%[data-disabled=\"false\"][data-preview~=\"hover\"] %label": "color:[txth];[trans]",
    "%link": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%label": "[fnt]  [trans]  color:[txt];white-space:nowrap;display:inline-block;",
    "%[data-disabled=\"true\"] %label,%[data-preview~=\"disabled\"] %label": "color:[txtd];"
  }
}
 skins['wysiwyg.viewer.skins.button.ViewerButtonBlueSkin'] = {
  "react": [
    [
      "div",
      "caption",
      [],
      {}
    ]
  ],
  "params": {
    "borderColor": "BORDER_COLOR",
    "$borderRadius": "BORDER_RADIUS",
    "bgColor": "BG_COLOR_ALPHA",
    "borderColorOver": "BORDER_COLOR",
    "bgColorOver": "BG_COLOR_ALPHA",
    "shadowColor": "BOX_SHADOW_COLOR_ALPHA",
    "shadowInsetColorOver": "BOX_SHADOW_COLOR_ALPHA",
    "shadowInsetColor": "BOX_SHADOW_COLOR_ALPHA",
    "labelColor": "TEXT_COLOR",
    "borderGrayColor": "BORDER_COLOR",
    "brw": "BORDER_SIZE",
    "bgGrayColor": "BG_COLOR_ALPHA",
    "borderGrayColorOver": "BORDER_COLOR",
    "bgGrayColorOver": "BG_COLOR_ALPHA",
    "labelGrayColor": "TEXT_COLOR",
    "bgDisabledColor": "BG_COLOR_ALPHA",
    "brdd": "BORDER_COLOR_ALPHA",
    "labelDisabledColor": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "borderColor": "#5b8fa7",
    "$borderRadius": "4px",
    "bgColor": "#2aa7ea",
    "borderColorOver": "#237aa7",
    "bgColorOver": "#179ce1",
    "shadowColor": "0, 0, 0, 0.2",
    "shadowInsetColorOver": "68, 175, 233, 0.7",
    "shadowInsetColor": "0, 0, 0, 0.7",
    "labelColor": "#ffffff",
    "borderGrayColor": "#c4c4c4",
    "brw": "0",
    "bgGrayColor": "#f9fafc",
    "borderGrayColorOver": "#89a4af",
    "bgGrayColorOver": "#f2f3f5",
    "labelGrayColor": "#24a0c4",
    "bgDisabledColor": "#f9fafc",
    "brdd": "#d5d5d5",
    "labelDisabledColor": "#c4c4c4"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%": "padding:0 8px;border:1px solid [borderColor];[$borderRadius]  background-color:[bgColor];position:relative;text-align:center;cursor:pointer;",
    "%[data-state~=\"over\"]": "border:1px solid [borderColorOver];background:[bgColorOver] none;box-shadow:0 2px 3px 0 [shadowColor], 0 1px 2px 0 [shadowInsetColorOver] inset;",
    "%[data-state~=\"pressed\"]": "background:[bgColor] none;border-color:transparent;box-shadow:0 1px 3px 0 [shadowInsetColor] inset;",
    "%caption": "color:[labelColor];height:1.8em;line-height:1.8em;",
    "%[data-state~=\"grayed\"]": "border:solid [borderGrayColor] [brw];background-color:[bgGrayColor];",
    "%[data-state~=\"grayed\"][data-state~=\"over\"]": "border:solid [borderGrayColorOver] [brw];background-color:[bgGrayColorOver];box-shadow:0 2px 3px 0 [shadowColor], 0 1px 2px 0 #ffffff inset;",
    "%[data-state~=\"grayed\"][data-state~=\"pressed\"]": "border-color:transparent;background-color:[bgGrayColor];",
    "%[data-state~=\"grayed\"] %caption": "color:[labelGrayColor];",
    "%[disabled]": "background-color:[bgDisabledColor];border:solid [brdd] [brw];",
    "%[disabled] %caption": "color:[labelDisabledColor];"
  }
}
 skins['wysiwyg.viewer.skins.button.WrappingButton'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "trans1": "TRANSITION",
    "shd": "BOX_SHADOW",
    "horizontalPadding": "PADDING_SIZE",
    "verticalPadding": "PADDING_SIZE",
    "fnt": "FONT",
    "trans2": "TRANSITION",
    "txt": "TEXT_COLOR",
    "bg": "BG_COLOR_ALPHA",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "bgh": "BG_COLOR_ALPHA",
    "brdh": "BORDER_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "bgd": "BG_COLOR_ALPHA",
    "brdd": "BORDER_COLOR_ALPHA",
    "txtd": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "rd": "0",
    "trans1": "border-color 0.4s ease 0s, background-color 0.4s ease 0s",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "horizontalPadding": "0",
    "verticalPadding": "0",
    "fnt": "font_5",
    "trans2": "color 0.4s ease 0s",
    "txt": "color_15",
    "bg": "color_17",
    "brd": "color_15",
    "brw": "0",
    "bgh": "color_18",
    "brdh": "color_15",
    "txth": "color_15",
    "bgd": "#CCCCCC",
    "brdd": "color_15",
    "txtd": "#FFFFFF"
  },
  "css": {
    "% button%link": "width:100%;",
    "%[data-state~=\"shouldUseFlex\"] %link,%[data-state~=\"shouldUseFlex\"] %labelwrapper": "text-align:initial;display:flex;align-items:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"center\"] %labelwrapper": "justify-content:center;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"left\"] %labelwrapper": "justify-content:flex-start;",
    "%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %link,%[data-state~=\"shouldUseFlex\"][data-state~=\"right\"] %labelwrapper": "justify-content:flex-end;",
    "%": "display:grid;grid-template-columns:minmax(0, 1fr);",
    "%link": "[rd]  [trans1]  [shd]  position:relative;padding-left:[horizontalPadding];padding-right:[horizontalPadding];padding-top:[verticalPadding];padding-bottom:[verticalPadding];",
    "%label": "[fnt]  [trans2]  position:relative;color:[txt];",
    "%[data-disabled=\"false\"] %link": "background-color:[bg];border:solid [brd] [brw];cursor:pointer !important;",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %link,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %link,%[data-disabled=\"false\"][data-preview~=\"hover\"] %link": "background-color:[bgh];border-color:[brdh];",
    "%[data-disabled=\"false\"]:active[data-state~=\"mobile\"] %label,%[data-disabled=\"false\"]:hover[data-state~=\"desktop\"] %label,%[data-disabled=\"false\"][data-preview~=\"hover\"] %label": "color:[txth];",
    "%[data-disabled=\"true\"] %link,%[data-preview~=\"disabled\"] %link": "background-color:[bgd];border:solid [brdd] [brw];",
    "%[data-disabled=\"true\"] %label,%[data-preview~=\"disabled\"] %label": "color:[txtd];",
    "%[data-state~=\"center\"] %label": "text-align:center;",
    "%[data-state~=\"left\"] %label": "text-align:left;",
    "%[data-state~=\"right\"] %label": "text-align:right;"
  }
}

module.exports = skins